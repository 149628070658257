import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../../../../commonComponents/forms/FormInput';

function TabHiddenAttributes(props) {
  const { t, hiddenAttributes, organizationHiddenAttributes } = props;
  const [attributesState, setAttributesState] = useState(
    organizationHiddenAttributes,
  );

  const hiddenAttributesList = useMemo(
    () =>
      hiddenAttributes?.map(hiddenAttribute => ({
        id: hiddenAttribute._id,
        text: hiddenAttribute.name,
      })),
    [hiddenAttributes],
  );

  const handleAttributeRemoval = useCallback(
    () => _id => {
      setAttributesState(prevState =>
        prevState.filter(attribute => attribute !== _id),
      );
    },
    [setAttributesState],
  );

  const handleAttributeAddition = useCallback(
    value => {
      setAttributesState(prevState => prevState.concat(value));
    },
    [setAttributesState],
  );

  return (
    <div className="hidden-attributes-tab">
      <h4>{t('product.hidden.attributes')}</h4>
      <input
        type="hidden"
        value={JSON.stringify(attributesState)}
        id="hiddenAttributesValue"
        name="hiddenAttributesValue"
      />
      <FormInput
        id="hiddenAttributes"
        name="hiddenAttributes"
        value={attributesState}
        onChange={handleAttributeAddition}
        label={t('product.hidden.attributes')}
        labelClassName="col-1 text-right"
        containerClassName="col-8"
        type="select2"
        placeholder={t('product.hidden.attributes')}
        hint={t('product.hidden.attributes.hint')}
        groupProps={{ className: 'row' }}
        inputProps={{
          width: '100%',
          searchable: true,
          multiple: true,
          items: hiddenAttributesList,
          onUnselect: handleAttributeRemoval(),
        }}
      />
    </div>
  );
}

TabHiddenAttributes.propTypes = {
  t: PropTypes.func.isRequired,
  hiddenAttributes: PropTypes.arrayOf({
    _id: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  organizationHiddenAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TabHiddenAttributes;
